<script>
import api from '@/api/index.js'
import tool from '@/tool'
import base from '@/templates/list'
export default {
  directives: {},
  filters: {},
  components: {
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: true, title: '新增' },
        remove: { show: true, title: '删除' },
        edit: { show: true, title: '编辑' },
        view: { show: false, title: '详情' },
        updateStatus: { show: false },
        enable: { show: true, title: 'global.上线' },
        valid: { show: true, title: 'global.下线' }
      },
      showList: [
        // {
        //   copName: 'el-select',
        //   key: 'status',
        //   type: '',
        //   attrs: ['placeholder', 'options'],
        //   placeholder: 'list.状态',
        //   options: [
        //     { label: this.$t('global.上线'), value: '0' },
        //     { label: this.$t('global.下线'), value: '1' }
        //   ]
        // }
      ],
      tableTile: [
        {
          columnLabel: '序号',
          type: 'index',
          width: '',
        },
        {
          columnLabel: '语言类型',
          prop: 'langType',
          width: '',
          formatter: (row, column, cellValue, index) => cellValue === 1 ? '中文' : 'English'
        },
        {
          columnLabel: '行业',
          prop: 'industry',
          width: '',
        },
        {
          key: 'title',
          columnLabel: '标题',
          prop: 'title',
          width: ''
        },
        {
          key: 'subTitle',
          columnLabel: '副标题',
          prop: 'subTitle',
          width: ''
        },
        {
          key: 'createTime',
          columnLabel: '创建日期',
          prop: 'createTime',
          width: '',
          formatter: (row, column, cellValue, index) => this.$moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          key: 'createUserName',
          columnLabel: '创建人',
          prop: 'createUserName',
          width: ''
        },
        {
          key: 'listImgUrl',
          columnLabel: '列表图',
          prop: 'listImgUrl',
          width: '',
          formatter: (row, column, cellValue, index) => {
            const h = this.$createElement
            return h('el-image', {
              attrs: {
                previewSrcList: [cellValue],
                src: cellValue
              },
              style: {
                width: '100px',
                height: '50px'
              }
            })
          }
        },
        {
          key: '1',
          columnLabel: '详情banner图',
          prop: 'bannerImgUrl',
          width: '',
          formatter: (row, column, cellValue, index) => {
            const h = this.$createElement
            return h('el-image', {
              attrs: {
                previewSrcList: [cellValue],
                src: cellValue
              },
              style: {
                width: '50px',
                height: '50px'
              }
            })
          }
        }
      ]
    }
  },
  created () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline,
          queryParam: null
        }
      }
      api.industryCase.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },
    // 新增
    add () {
      this.$router.push('/industry-case-add')
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      const row = this.tableData.find(item => item.id === id)
      await api.industryCase.remove({ id: id, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const row = this.tableData.find(item => item.id === id)
      this.$router.push('/industry-case-add/' + id + `/${row.associatedId}`)
    },
    // 启用
    async enable (id) {
      await this.$confirm(this.$t('global.enableTooltip').replace('停用', '下线'), { type: 'warning' })
      const row = this.tableData.find(item => item.id === id)
      await api.industryCase.updateStatusById({ id: id, status: 1, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 停用
    async disable (id) {
      await this.$confirm(this.$t('global.disableTooltip').replace('启用', '上线'), { type: 'warning' })
      const row = this.tableData.find(item => item.id === id)
      await api.industryCase.updateStatusById({ id: id, status: 0, associatedId: row.associatedId })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    }
  }
}
</script>
